body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  overflow-y: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
  height: 93vh;
}

.blurFilter {
  position: fixed;
  backdrop-filter: blur(10px);
  z-index: 10;
  width: 100%;
  height: calc(75vh - 1px);
}

.searchBar {
  width: fit-content;
  display: block;
  margin: 25vh auto;
}

.container {
  overflow: hidden;
}

.worldBackground {
  background: url('../public/worldmap2x.png') repeat-x;
  height: 75vh;
  width: 500vh;
  background-size: 50% 75vh;

  animation: slide 30s linear infinite;
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-250vh, 0, 0);
  }
}
